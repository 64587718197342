import type * as Types from '../../__generated__/types';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  name
  avatarUrl
}
    `;
export const MyDocument = gql`
    query My {
  my {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MyGQL extends Apollo.Query<MyQuery, MyQueryVariables> {
    document = MyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginUserDocument = gql`
    mutation loginUser($input: LoginUserInput!) {
  loginUser(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginUserGQL extends Apollo.Mutation<LoginUserMutation, LoginUserMutationVariables> {
    document = LoginUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export type MyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyQuery = { my: { id: any, email: string, name: string, avatarUrl?: string | null } };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: { boolean?: boolean | null } };

export type LoginUserMutationVariables = Types.Exact<{
  input: Types.LoginUserInput;
}>;


export type LoginUserMutation = { loginUser: { boolean?: boolean | null } };

export type UserFragment = { id: any, email: string, name: string, avatarUrl?: string | null };
